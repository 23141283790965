<template>
  <div>
    <!-- begin:: Content Head -->
    <KTSubheader v-bind:title="'Messages'" />
    <!-- end:: Content Head -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"
            >{{ id ? "Edit" : "Create" }} Message</span
          >
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <b-form @submit="onSubmit" v-if="show">
            <b-form-radio-group
              id="btn-radios-2"
              v-model="form.messageType"
              :options="messageTypes"
              buttons
              button-variant="outline-primary"
              size="lg"
              name="radio-btn-outline"
              class="col-md-4"
            ></b-form-radio-group>

            <b-form-group
              id="all-messages-input-group"
              label="Parent:"
              label-for="all-messages-input"
              class="col-md-3"
            >
              <b-form-select
                id="all-messages-input"
                v-model="form.parentId"
                :options="allMessagesList"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Language:"
              label-for="input-3"
              class="col-md-3"
            >
              <b-form-select
                v-if="form.messageType == 'say'"
                id="input-3"
                v-model="form.sayLanguage"
                :options="sayLanguages"
                required
              ></b-form-select>
              <b-form-select
                v-if="form.messageType == 'play'"
                id="input-3"
                v-model="form.language"
                :options="languages"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group
              v-if="form.messageType == 'say'"
              id="input-group-1"
              label="Message:"
              label-for="input-1"
              class="col-md-6"
            >
              <b-form-input
                id="input-1"
                v-model="form.message"
                required
                placeholder="Enter message"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Digit:"
              label-for="input-2"
              class="col-md-3"
            >
              <b-form-input
                id="input-2"
                v-model="form.digit"
                type="number"
                required
                placeholder="Enter digit"
              ></b-form-input>
            </b-form-group>

            <b-form-file
              v-if="form.messageType == 'play'"
              v-model="form.file"
              :state="Boolean(form.file)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              class="col-md-3"
              accept="audio/mpeg"
            ></b-form-file>
            <br /><br />

            <router-link :to="{ name: 'messages' }">
              <b-button type="button" variant="secondary">Cancel</b-button>
            </router-link>
            <b-button type="submit" variant="primary" class="ml-3"
              >Submit</b-button
            >
          </b-form>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import {
  GET_ALL_MESSAGES_LIST,
  GET_MESSAGE,
  CREATE_MESSAGE,
  UPDATE_MESSAGE
} from "@/core/services/store/message.module.js";

export default {
  name: "edit-message",
  components: {
    KTSubheader
  },
  data: () => ({
    id: null,
    form: {
      parentId: null,
      messageType: "say",
      digit: 1,
      message: "",
      language: "en-US",
      sayLanguage: "en-US",
      file: null
    },
    messageTypes: [
      { text: "Say", value: "say" },
      { text: "Play", value: "play" }
    ],
    languages: [
      { text: "Select One", value: null, canSay: true, disabled: true },
      {
        value: "sq-AL",
        text: "Shqip, Albanian",
        canSay: false
      },
      {
        value: "sr-RS",
        text: "Serbian, Serbia",
        canSay: false
      },
      {
        value: "da-DK",
        text: "Danish, Denmark",
        canSay: true
      },
      {
        value: "de-DE",
        text: "German, Germany",
        canSay: true
      },
      {
        value: "en-US",
        text: "English, United States",
        canSay: true
      },
      {
        value: "es-ES",
        text: "Spanish, Spain",
        canSay: true
      },
      {
        value: "fi-FI",
        text: "Finnish, Finland",
        canSay: true
      },
      {
        value: "fr-FR",
        text: "French, France",
        canSay: true
      },
      {
        value: "it-IT",
        text: "Italian, Italy",
        canSay: true
      },
      {
        value: "ja-JP",
        text: "Japanese, Japan",
        canSay: true
      },
      {
        value: "ko-KR",
        text: "Korean, Korea",
        canSay: true
      },
      {
        value: "nb-NO",
        text: "Norwegian, Norway",
        canSay: true
      },
      {
        value: "nl-NL",
        text: "Dutch, Netherlands",
        canSay: true
      },
      {
        value: "pl-PL",
        text: "Polish, Poland",
        canSay: true
      },
      {
        value: "pt-BR",
        text: "Portuguese, Brazil",
        canSay: true
      },
      {
        value: "pt-PT",
        text: "Portuguese, Portugal",
        canSay: true
      },
      {
        value: "ru-RU",
        text: "Russian, Russia",
        canSay: true
      },
      {
        value: "sv-SE",
        text: "Swedish, Sweden",
        canSay: true
      },
      {
        value: "zh-CN",
        text: "Chinese (Mandarin)",
        canSay: true
      },
      {
        value: "zh-HK",
        text: "Chinese (Cantonese)",
        canSay: true
      },
      {
        value: "zh-TW",
        text: "Chinese (Taiwanese Mandarin)",
        canSay: true
      }
    ],
    show: true
  }),
  async mounted() {
    this.$store.dispatch(GET_ALL_MESSAGES_LIST, this.id);
    this.id = this.$route.params.id;
    if (this.id) {
      await this.$store.dispatch(GET_MESSAGE, this.id);
      this.form = this.message;
    }
  },
  computed: {
    ...mapState({
      message: state => state.message.message,
      allMessages: state => state.message.allMessages
    }),
    sayLanguages() {
      return this.languages.filter(l => l.canSay);
    },
    allMessagesList() {
      const messages = this.allMessages.map(item => {
        const value = item.messageType == "say" ? item.message : item.fileName;
        return { value: item.id, text: `${item.messageType}: ${value}` };
      });

      messages.unshift({ value: null, text: "NONE" });

      return messages;
    }
  },
  watch: {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      let payload = {};
      payload.messageType = this.form.messageType;

      if (this.form.messageType == "say") {
        payload.data = {
          parentId: this.form.parentId,
          messageType: this.form.messageType,
          language: this.form.sayLanguage,
          message: this.form.message,
          digit: `${this.form.digit}`
        };
      } else if (this.form.messageType == "play") {
        if (!this.form.file || this.form.file.type != "audio/mpeg") {
          this.$store.dispatch("showSnackbar", {
            message: "Upload valid audio file",
            color: "error"
          });
          return;
        }
        const formData = new FormData();
        formData.append("file", this.form.file, this.form.file.name);
        formData.append("parentId", this.form.parentId);
        formData.append("messageType", this.form.messageType);
        formData.append("language", this.form.language);
        formData.append("digit", this.form.digit);

        payload.data = formData;
      } else {
        this.$store.dispatch("showSnackbar", {
          message: "Invalid message type",
          color: "error"
        });
        return;
      }

      if (this.id) {
        payload.id = this.id;

        this.$store
          .dispatch(UPDATE_MESSAGE, payload)
          .then(data => {
            this.$router.push("/messages");
          })
          .catch(error => {});
      } else {
        this.$store
          .dispatch(CREATE_MESSAGE, payload)
          .then(data => {
            this.$router.push("/messages");
          })
          .catch(error => {});
      }
    }
  }
};
</script>
